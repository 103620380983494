.HotspotWrapper {
  margin: 1rem;
  text-align: center;
}

.Hotspot {
  position: relative;
  display: inline-block;
}

.Hotspot__image {
  padding: 0;
  max-width: 100%;
  max-height: 100%;
}

.Hotspot__spot {
  position: absolute;
  height: 40px;
  width: 40px;
  background: #be6119;
  border-radius: 50%;
  animation: scale 400ms ease-in-out infinite alternate;
  cursor: pointer;
}

.Hotspot__spot:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
  background: #fff;
  border-radius: 50%;
  color: #fff;
  content: '';
}

.Hotspot__spot[data-active='true'] {
  animation: none;
  transform: scale(1.5);
}

.Hotspot__spot[data-active='true']:after {
  background: #fff;
}

@keyframes scale {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.Hotspot__info {
  position: absolute;
  top: 1rem;
  left: 1rem;
  max-width: 300px;
  padding: 1.5rem;
  background: #fff;
  z-index: 100;
  color: #a8aa9c;
}

.Hotspot__infoLink {
  margin-bottom: 0.5em;
  display: block;
  font-weight: bold;
  color: #be6119;
}
